<template>
  <div>
    <the-breadcrumbs></the-breadcrumbs>
    <page-content page-title="Export der Veranstalter">
      <template #actions>
        <region-chooser
          v-if="$store.getters['auth/isSuperAdmin']"
          @region:chosen="regionChanged"
        ></region-chooser>
      </template>
      <el-card>
        <div slot="header">
          <span
            >Exportieren Sie alle Veranstalter als CSV-Datei (für Nutzung in
            Excel o.ä.)</span
          >
        </div>
        <div>
          <el-button type="primary" @click="download"
            >Download der CSV-Datei</el-button
          >
        </div>
      </el-card>
    </page-content>
  </div>
</template>

<script>
import { format } from "date-fns"
import fileDownload from "js-file-download"
import Repository from "@/repositories/api.js"
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import PageContent from "@/components/PageContent"
import RegionChooser from "@/components/RegionChooser"

export default {
  metaInfo: {
    title: "Export Veranstalter"
  },
  components: {
    TheBreadcrumbs,
    PageContent,
    RegionChooser
  },
  data() {
    return {
      format
    }
  },
  computed: {},
  async created() {},
  methods: {
    regionChanged() {
      //
    },
    async download() {
      let response = await Repository.get(
        "/admin/support_organizations/export",
        {
          params: { region_id: this.$store.state.auth.chosenRegion }
        }
      )
      fileDownload(
        response.data,
        `veranstalter_${this.format(new Date(), "yyyy-LL-dd")}.csv`
      )
    }
  }
}
</script>
